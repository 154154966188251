
import { defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import SenderIdTable from '@/components/pages/chats/settings/byoc/senderId/SenderIdTable.vue'
import LinkNumbersTable from '@/components/pages/chats/settings/channels/flow/linkNumbers/LinkNumbersTable.vue'
import type { TableHeaders } from '@/definitions/shared/types'
import { NumbersMockWithNoLinked, SendersIdMock } from '@/definitions/services/byoc/data'

export default defineComponent({
  components: {
    TmButton,
    TmModal,
    SenderIdTable,
    LinkNumbersTable,
  },
  props: {
    modalTitle: {
      type: String,
      default: 'Link numbers',
    },
  },
  setup() {
    const tableSenderHeaders = ref<TableHeaders[]>([
      { text: 'Sender ID', value: 'sender-id-slot' },
      { text: 'Calls', value: 'voice-slot', width: '10%' },
      { text: 'SMS', value: 'sms-slot', width: '10%' },
      { text: 'MMS', value: 'mms-slot', width: '20%' },
      { text: 'Status', value: 'status-slot' },
    ])
    const tableSenderItems = ref(SendersIdMock.map((item: any) => ({
      ...item,
      disable: item.status.name === 'linked',
    })))
    const tableHeaders = ref<TableHeaders[]>([
      { text: 'Number', value: 'number-slot' },
      { text: 'Label', value: 'label-slot' },
      { text: 'Voice', value: 'voice-slot', width: '80px' },
      { text: 'SMS', value: 'sms-slot', width: '80px' },
      { text: 'MMS', value: 'mms-slot', width: '160px' },
      { text: 'Status', value: 'status-slot' },
    ])

    const selectedSender = ref([])
    const selected = ref([])

    return {
      tableSenderHeaders,
      tableSenderItems,
      selectedSender,
      tableHeaders,
      selected,
      tableItems: NumbersMockWithNoLinked,
    }
  },
})
